<script setup lang="ts">
import { computed } from 'vue'

const props = defineProps({
  level: {
    type: String,
    default: '',
  },
  type: {
    type: String,
    default: 'kids',
  },
  email: {
    type: String,
    default: '',
  },
  classId: {
    type: String,
    default: '',
  },
})

const engderPath = computed(() => {
  return props.type == 'kids'
    ? `https://engder.globish.co.th/kids/${props.level}?user=${props.email}&classId=${props.classId}`
    : `https://engder.globish.co.th/?level=${props.level}?user=${props.email}&classId=${props.classId}`
})
</script>

<template>
  <div class="placeholder _prevent-text-select">
    <iframe :src="engderPath" allow="microphone" credentialless frameborder="0"></iframe>
  </div>
</template>

<style lang="scss" scoped>
iframe {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.placeholder {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  @include font-weight(regular);
  font-size: 1.5rem;
  text-align: center;
  background: rgba(#ffffff, 0.06);
  color: $gs-5;

  @include media-breakpoint-up-custom(700px) {
    font-size: 1.6rem;
  }

  .illustration {
    color: var(--primary-color);
  }
}
</style>
