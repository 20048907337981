<script setup lang="ts">
/**
 * Import types
 */
import { Engine } from '@/types/enums/room.enum'
/**
 * import component
 */
import BrowserSupportPopup from '@/components/checkup/BrowserSupportPopup.vue'
import DeviceAlertPopup from '@/components/checkup/DeviceAlertPopup.vue'
import Joining from '@/components/room/Joining.vue'
import PreviewBeforeJoin from '@/components/room/PreviewBeforeJoin.vue'

/**
 * Import composables & store
 */
import { computed, defineAsyncComponent, onBeforeUnmount, ref } from 'vue'
import useRoomByEngine from '@/composables/room/useRoomByEngine'
import { useDeviceStore, useRoomStore } from '@/stores'
import useSocketEvent from '@/composables/room/useSocketEvent'

const TwilioRoom = defineAsyncComponent(() => import('@/components/room/twilio/TwilioRoom.vue'))
const ZoomRoom = defineAsyncComponent(() => import('@/components/room/zoom/ZoomNewRoom.vue'))

const device = useDeviceStore()

const isJoinRoom = ref<boolean>(false)
const isJoining = ref<boolean>(false)
const { socket } = useSocketEvent()
const {
  // Ref
  isOpenPopup,
  roomProps,

  // Methods
  addParticipantInRoom,
  joinRoom,
  onEngineDisconnect,
  onParticipantConnect,
  onParticipantDisconnect,
  onParticipantShareScreen,
  addFocusCompartment,
  popoutCompartment,
} = useRoomByEngine()

const roomStore = useRoomStore()
/**
 * Computed
 */
const roomComponent = computed(() => {
  if (roomStore.currentEngine === Engine.TWILIO) return TwilioRoom
  else if (roomStore.currentEngine === Engine.ZOOM) return ZoomRoom
  else return undefined
})

const canJoinRoom = computed(() => {
  if (roomStore.currentEngine === Engine.ZOOM) return true
  else return true
})

/**
 * Methods
 */
const participantClickJoinRoom = async () => {
  isJoinRoom.value = true
  isJoining.value = true
  device.setStateEnableToLocal()
  await joinRoom()
  isJoining.value = false
}
const connectRoom = () => {
  participantClickJoinRoom()
}

// TODO: old version use twilio and new use zoom
const switchDevices = (isRepublish: boolean) => {
  window.location.reload()
}

onBeforeUnmount(() => {
  socket.disconnect()
})
</script>

<template>
  <DeviceAlertPopup
    v-model="isOpenPopup"
    :content="device.permissionError?.message || 'Some device changed please republish again'"
    :is-republish="isJoinRoom || device.someDeviceIsGone"
    @republish="switchDevices(true)"
  />
  <BrowserSupportPopup
    v-if="roomStore.room?.currentEngine"
    :engine="roomStore.room?.currentEngine"
  />
  <template v-if="isJoinRoom">
    <Joining :is-joining="isJoining" />
    <component
      :is="roomComponent"
      v-if="roomComponent && !isJoining && roomStore.room.id && roomStore.localParticipantInfo"
      v-bind="roomProps"
      @on-participant-connect="onParticipantConnect"
      @on-participant-disconnect="onParticipantDisconnect"
      @on-participant-share-screen="onParticipantShareScreen"
      @on-engine-disconnect="onEngineDisconnect"
      @on-subsribe-participant-in-room="addParticipantInRoom"
      @on-focus-participant="addFocusCompartment"
      @on-popout-participant="popoutCompartment"
    ></component>
  </template>
  <template v-else>
    <PreviewBeforeJoin :can-join-room="canJoinRoom" @join-room="connectRoom" />
  </template>
</template>
