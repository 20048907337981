export enum Engine {
  TWILIO = 'twilio',
  TOKBOX = 'tokbox',
  WHEREBY = 'whereby',
  ZOOM = 'zoom',
}

export enum Device {
  DESKTOP = 'desktop',
  MOBILE = 'mobile',
}

export enum VideoRoomConnectionState {
  NotInitialised = 'NotInitialised',
  Connecting = 'Connecting',
  ConnectFailed = 'ConnectFailed',
  Connected = 'Connected',
}

export enum ConnectingLogsAction {
  CONNECT = 'connect',
  DISCONNECT = 'disconnect',
}

export enum ZoomRoomConnectingState {
  NOT_INITIALIZE = 'not_initialize',
  CONNECTING = 'connecting',
  CONNECTING_FAILED = 'connecting_failed',
  CONNECTED = 'connected',
  BROWSER_NOT_SUPPORT = 'browser_not_support',
}
