interface RowColConfig {
  default: {
    [key: number]: number[]
  }

  defaultMd: {
    [key: number]: number[]
  }
  focus: {
    [key: number]: number[]
  }
}

const flexConfig: RowColConfig = {
  default: {
    1: [1, 1],
    2: [1, 2],
    3: [2, 2],
    4: [2, 2],
    5: [2, 3],
    6: [2, 3],
    7: [2, 4],
    8: [2, 4],
    9: [3, 4],
  },
  defaultMd: {
    1: [1, 1],
    2: [1, 2],
    3: [2, 2],
    4: [2, 2],
    5: [3, 2],
    6: [3, 2],
    7: [4, 2],
    8: [4, 2],
    9: [5, 2],
  },
  focus: {
    1: [1, 1],
    2: [2, 1],
    3: [3, 1],
    4: [2, 2],
    5: [3, 2],
    6: [3, 2],
    7: [3, 3],
  },
}

export const getOptimalFlexRowCol = (
  nCompartment: number,
  isFocus: boolean,
  mediaSize?: string,
) => {
  const type = isFocus ? 'focus' : mediaSize === 'md' ? 'defaultMd' : 'default'
  let row
  let col
  try {
    ;[row, col] = flexConfig[type][nCompartment]
  } catch {
    const optimalCol = Math.ceil(Math.sqrt(nCompartment)) || 1
    const optimalRow = Math.ceil(nCompartment / optimalCol) || 1
    ;[row, col] = [optimalRow, optimalCol]
  }
  return [row, col]
}

interface ICalculateWidthHeightParams {
  elementWidth: number
  elementHeight: number
  gap: number
  nCols: number
  nRows: number
}
export const calculateWidthHeight = (params: ICalculateWidthHeightParams) => {
  const marginWidth = 30
  const marginHeight = 152
  const { elementWidth, elementHeight, gap, nCols, nRows } = params

  const maxWidth = Math.floor((elementWidth - marginWidth - gap * (nCols - 1)) / nCols)
  const maxHeight = Math.floor((elementHeight - marginHeight - gap * (nRows - 1)) / nRows)

  const heightRatio = Math.floor((maxWidth * 9) / 16)
  const height = heightRatio > maxHeight ? maxHeight : heightRatio

  const widthRatio = Math.floor((height * 16) / 9)
  if (widthRatio * 1.5 < maxWidth) return { width: widthRatio * 1.5, height }
  else return { width: maxWidth, height }
}
