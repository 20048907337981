<script setup lang="ts">
import DocController from '@/components/document/DocController.vue'
/**
 * define props
 */
const props = defineProps({
  docPath: {
    type: String,
    required: true,
  },
  isCoachRole: {
    type: Boolean,
    default: false,
  },
})

/**
 * define emits
 */
const emit = defineEmits(['toggle-original-material'])
</script>

<template>
  <div class="og-wrapper">
    <DocController
      :has-doc-path="true"
      :show-back-msg="true"
      :can-open-original-doc="true"
      :is-coach-role="isCoachRole"
      @toggle-original-material="emit('toggle-original-material')"
    />
    <iframe :src="docPath" width="100%" height="100%" credentialless style="border: none"></iframe>
  </div>
</template>

<style lang="scss" scoped>
.og-wrapper {
  width: 100%;
  height: 100%;
}
</style>
