import { localParticipantInfo, participantsInfo } from '@/composables/room/useRoomState'
import useTheme from '@/composables/useTheme'
import { ALLOW_TIME_AUTO, AVATAR_DEFAULT } from '@/data/room'
import { changeFavicon } from '@/helpers/favicon'
import { transformJson, transformPlain } from '@/helpers/transform.helper'
import { findParticipantInfoByEmail } from '@/helpers/participant.helper'
import { IParticipantInfo, ISpaceLocalParticipant } from '@/types/interfaces/participant.interface'
import { IRoom, IRoomMetaData } from '@/types/interfaces/room.interface'
import { TCapabilitiesJSON, TParticipantInfo } from '@/types/transforms/participant.transform'
import { defineStore } from 'pinia'
import { computed, reactive, toRefs } from 'vue'
import { Engine } from '@/types/enums/room.enum'
import moment from 'moment'
import hexToRgb from '@/helpers/hex-to-rgb'
import { remoteParticipants } from '@/composables/twilio/useTwilioState'
import useSpaceApi from '@/composables/api/useSpaceApi'
import AuthState from '@/services/auth-state'
import useAuth from '@/composables/api/useAuth'
import { VERSION } from '@/composables/api'

interface UseRoomState {
  room: IRoom
  localParticipant: ISpaceLocalParticipant
  localParticipantInfo?: IParticipantInfo
  participants: IParticipantInfo[]
  token: string
  roomDuration: number
  currentEngine: Engine
  isMultipleSessionDetected: boolean
}

interface SetBrowserDetail {
  color?: string
  name?: string
  favicon?: string
}

export const useRoomStore = defineStore('room', () => {
  const state = reactive<UseRoomState>({
    room: {} as IRoom,
    localParticipant: {} as ISpaceLocalParticipant,
    localParticipantInfo: {} as IParticipantInfo,
    participants: [],
    token: '',
    roomDuration: 25,
    currentEngine: Engine.ZOOM,
    isMultipleSessionDetected: false,
  })

  const { getAccessInfo } = useAuth()

  const {
    getRoomInfo,
    getJoinTokenWithZoom,
    connectRoom,
    getParticipantsByRoomId,
    saveLogsAttend,
  } = useSpaceApi()

  const { changeTheme, setTemplate, theme: themeMode } = useTheme()

  /**
   *Computed
   */
  const isCoachRole = computed(() => state.localParticipantInfo?.isCoachRole)
  const isStudentRole = computed(() => state.localParticipantInfo?.isStudentRole)

  const releaseVersion = computed(() =>
    VERSION.toLowerCase().includes('v') ? VERSION.toLowerCase() : `v${VERSION}`,
  )
  const currentTag = computed(() => {
    const classId = parseInt(state.room.externalRoomId)
    const currentVersion = `${releaseVersion.value}-${state.currentEngine}`
    return classId ? `${currentVersion}-${classId}` : currentVersion
  })

  const roomDuration = computed(() =>
    moment(state.room.endTime).diff(state.room.startTime, 'minute'),
  )

  const isNightTime = computed(() => {
    const formatHour = 'HH:mm:ss'
    const classStartTime = moment(state.room?.startTime).format(formatHour)
    return moment(classStartTime, formatHour)
      .tz('Asia/Bangkok')
      .isBetween(
        moment(ALLOW_TIME_AUTO.START, formatHour),
        moment(ALLOW_TIME_AUTO.END, formatHour),
        undefined,
        '[)',
      )
  })

  const getRoom = async () => {
    const res = await getRoomInfo()
    state.localParticipant = res.data.participant
    state.room = { ...res.data.room, metaData: undefined }
    if (res.data.room.metaData) {
      state.room.metaData = JSON.parse(res.data.room.metaData) as IRoomMetaData
    }
    if (state.room.externalRoomId) {
      sessionStorage.setItem('external_room_id', state.room.externalRoomId)
    }
    sessionStorage.setItem(
      'room_datetime',
      JSON.stringify({ startTime: state.room.startTime, endTime: state.room.endTime }),
    )
    setBrowserDetail({
      color: state.room?.color,
      name: state.room?.name,
      favicon: state.room?.favicon,
    })
  }

  const setBrowserDetail = (room: SetBrowserDetail) => {
    /**
     * Set primary color variable, favicon icon and title
     */
    document.documentElement.style.setProperty('--primary-color', room?.color || '#fff')
    const color = hexToRgb(room?.color || '#fff')
    if (color) {
      document.documentElement.style.setProperty(
        '--primary-color-rgb',
        `${color.r}, ${color.g}, ${color.b}`,
      )
      document.title = room?.name || 'Globish Tiger'
      changeFavicon(room?.favicon || '')
    }
    setRoomUi()
  }

  const setRoomUi = () => {
    /**
     * Set theme and template into room
     */
    if (state.room.metaData?.theme && themeMode.value === 'auto') {
      const theme =
        state.room?.metaData?.theme !== ('light' || 'dark') ? 'auto' : state.room?.metaData?.theme
      changeTheme(theme)
    }
    setTemplate(state.room?.metaData?.templateType || 'adult')
  }

  const getParticipants = async () => {
    try {
      const res = await getParticipantsByRoomId(state.room.id)
      state.participants = res.data?.map((participant) => {
        const capabilities = transformJson(TCapabilitiesJSON, participant?.capabilities)
        return transformPlain(TParticipantInfo, {
          ...participant,
          capabilities,
          avatar:
            !participant.avatar || participant.avatar?.includes('globish-logo')
              ? AVATAR_DEFAULT
              : participant.avatar,
        })
      })
      state.localParticipantInfo = findParticipantInfoByEmail(
        state.localParticipant.email,
        state.participants,
      )
      participantsInfo.value = state.participants
      localParticipantInfo.value = state.localParticipantInfo
    } catch (err) {
      console.log(err)
    }
  }

  const getZoomToken = async () => {
    const { roomId, participantId } = getAccessInfo()
    if (state.currentEngine === Engine.ZOOM && roomId && participantId) {
      let zoomToken = AuthState.getZoomClientToken() || ''
      if (!zoomToken) {
        const res = await getJoinTokenWithZoom()
        if (res?.data?.token) {
          zoomToken = AuthState.setZoomClientToken(res?.data?.token)
        }
      }
      state.token = zoomToken
    }
  }

  const getRoomInitialInfo = async (engine?: Engine) => {
    await getRoom()
    const setEngine = state.room.forceUseWhereby
      ? Engine.WHEREBY
      : engine || state.room.currentEngine
    setCurrentEngine(setEngine)
    await Promise.all([getParticipants(), getZoomToken()])
  }

  const connect = async (isWhereby = false) => {
    try {
      if (!state.room.id) {
        await getRoomInitialInfo(isWhereby ? Engine.WHEREBY : undefined)
      }
      const res = await connectRoom({
        roomId: state.room.id,
        participantId: state.localParticipant.id,
        engine: isWhereby ? Engine.WHEREBY : state.currentEngine,
        token: state.currentEngine === Engine.ZOOM ? state.token : undefined,
      })
      state.token = res.data.connectData.token
      if (isWhereby) {
        await saveLogsAttend(Engine.WHEREBY)
      }
    } catch (err) {
      console.log(err)
      alert('Cannot connect room')
    }
  }

  const setDefaultState = () => {
    remoteParticipants.value = []
    state.participants = []
  }

  const setCurrentEngine = (engine: Engine) => {
    state.currentEngine = engine
  }

  return {
    ...toRefs(state),
    // Computed
    roomDuration,

    // Methods
    connect,
    getRoom,
    getParticipants,
    setBrowserDetail,
    setDefaultState,
    isCoachRole,
    isStudentRole,
    getRoomInitialInfo,
    setCurrentEngine,
    releaseVersion,
    currentTag,
    isNightTime,
  }
})
