<script setup lang="ts">
import { computed, PropType, ref } from 'vue'
import ControlButton from '@/components/room/ControlButton.vue'
import useTheme from '@/composables/useTheme'
import { MoonIcon, SunIcon } from '@heroicons/vue/24/outline'
import useAuth from '@/composables/api/useAuth'
import { logoPathDark, logoPathLight, logoIcon } from '@/data/room'
import { IParticipantCapabilities } from '@/types/interfaces/participant.interface'
import LessonPopup from '@/components/room/LessonPopup.vue'
import LeavePopup from '@/components/room/LeavePopup.vue'

interface RoomControlEmits {
  (e: 'toggle-doc', value: boolean): void
  (e: 'toggle-whiteboard', value: boolean): void
  (e: 'toggle-chat', value: boolean): void
  (e: 'toggle-sticker', value: boolean): void
  (e: 'toggle-game', value: boolean): void
}

const { getAccessInfo } = useAuth()

const props = defineProps({
  userPermissions: {
    type: Object as PropType<IParticipantCapabilities>,
    required: true,
  },
  documentEnable: {
    type: Boolean,
    default: false,
  },
  whiteboardEnable: {
    type: Boolean,
    default: false,
  },
  stickerEnable: {
    type: Boolean,
    default: false,
  },
  chatEnable: {
    type: Boolean,
    default: false,
  },
  gameEnable: {
    type: Boolean,
    default: false,
  },
  nCompartments: {
    type: Number,
    default: 1,
  },
  exitPath: {
    type: String,
    default: '',
  },
  haveTrackLesson: {
    type: Boolean,
    default: false,
  },
})
const emit = defineEmits<RoomControlEmits>()
/**
 * State
 */
const { isDark, changeTheme } = useTheme()
const isOpenedLessonPopup = ref(false)
const isSavedLesson = ref(false)
const isOpenedLeavePopup = ref(false)
const isFromLessonBtn = ref(false)
const lessonTooltip = ref(false)
const classId = ref(parseInt(sessionStorage.getItem('external_room_id') || ''))
/**
 * Computed
 */
const logo = computed(() => (isDark.value ? logoPathDark : logoPathLight))
const showTrackLesson = computed(
  () =>
    !!classId.value &&
    !isNaN(classId.value) &&
    props.userPermissions?.trackLesson &&
    props.haveTrackLesson,
)

/**
 * Methods
 */

const goToMainPage = () => {
  const { queryToken } = getAccessInfo()
  window.location.href = `/?token=${queryToken}`
}

const clickLeaveButton = (fromlessonBtn?: boolean) => {
  isFromLessonBtn.value = fromlessonBtn || false
  if (showTrackLesson.value) {
    isOpenedLessonPopup.value = true
  } else {
    isOpenedLeavePopup.value = true
  }
}

const submitLessonSuccess = () => {
  isSavedLesson.value = true
  if (isFromLessonBtn.value) return
  isOpenedLeavePopup.value = true
}

const clickLeaveFromLessonPopup = () => {
  isOpenedLessonPopup.value = false
  isOpenedLeavePopup.value = true
}
</script>
<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="nav">
    <div class="left">
      <div class="logo-wrapper">
        <img
          class="_prevent-text-select logo"
          :src="logo"
          draggable="false"
          crossorigin="anonymous"
          @click="goToMainPage"
        />
        <img
          class="_prevent-text-select logo-icon"
          :src="logoIcon"
          draggable="false"
          crossorigin="anonymous"
          @click="goToMainPage"
        />
      </div>

      <div
        class="them-wrapper p-1 _pointer-on-hover rounded-full hover:text-gray-700 hover:bg-gray-200 dark:hover:bg-gray-700 dark:hover:text-white"
      >
        <!-- Change Theme -->
        <MoonIcon v-if="isDark" aria-hidden="true" @click="changeTheme('light')" />
        <SunIcon v-else aria-hidden="true" @click="changeTheme('dark')" />
      </div>
    </div>
    <div class="right">
      <Teleport to="body">
        <LeavePopup v-model="isOpenedLeavePopup" :exit-path="exitPath" />
      </Teleport>
      <Teleport to="body">
        <LessonPopup
          v-model="isOpenedLessonPopup"
          :class-id="classId"
          :is-from-lesson-btn="isFromLessonBtn"
          @success="submitLessonSuccess"
          @leave="clickLeaveFromLessonPopup"
        />
      </Teleport>
      <ControlButton
        v-if="userPermissions?.doc"
        name="Doc"
        icon="doc"
        :disabled="!documentEnable"
        @click="emit('toggle-doc', !documentEnable)"
      />
      <ControlButton
        v-if="userPermissions?.whiteBoard"
        name="Board"
        icon="board"
        :disabled="!whiteboardEnable"
        @click="emit('toggle-whiteboard', !whiteboardEnable)"
      />
      <ControlButton
        v-if="userPermissions?.engder"
        name="Game"
        icon="game"
        :disabled="!gameEnable"
        :is-ready="nCompartments == 1"
        @click="emit('toggle-game', !gameEnable)"
      />
      <ControlButton
        v-if="userPermissions?.sticker"
        name="Sticker"
        icon="sticker"
        :disabled="!stickerEnable"
        @click="emit('toggle-sticker', !stickerEnable)"
      />
      <div class="chat-wrapper">
        <ControlButton
          v-if="userPermissions?.chat"
          name="Chat"
          icon="chat"
          :disabled="!chatEnable"
          :badge="chatNotifyCount > 0 ? `${chatNotifyCount}` : ''"
          @click="emit('toggle-chat', !chatEnable)"
        />
      </div>
      <Popper
        v-if="showTrackLesson"
        :show="isSavedLesson ? lessonTooltip : false"
        :hover="true"
        :arrow="true"
        :content="$t('lesson-tooltip')"
        @mouseover="lessonTooltip = true"
        @mouseleave="lessonTooltip = false"
      >
        <ControlButton
          class="lesson-button"
          :name="$t('lesson')"
          icon="pen"
          :disabled="true"
          @click="clickLeaveButton(true)"
        />
      </Popper>
      <ControlButton
        class="leave-button"
        name="Leave"
        icon="leave"
        :disabled="true"
        @click="clickLeaveButton(false)"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.nav {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  @include font-weight(regular);
  flex-wrap: nowrap;
  overflow-x: auto;
  &::-webkit-scrollbar {
    display: none;
  }

  .left {
    display: flex;
    align-items: center;
    margin-right: 15px;

    > *:not(:last-child) {
      margin-right: 10px;
    }
    .logo-wrapper {
      min-width: 35px;
      img {
        &:hover {
          cursor: pointer;
        }
      }
      .logo {
        height: 32px;
      }
      .logo-icon {
        width: 35px;
      }

      @media screen and (max-width: 430px) {
        .logo {
          display: none;
        }
        .logo-icon {
          display: block;
        }
      }
      @media screen and (min-width: 431px) {
        .logo {
          display: block;
        }
        .logo-icon {
          display: none;
        }
      }
    }
  }

  .right {
    display: flex;
    align-items: center;

    > *:not(:first-child) {
      margin-left: 9px;
    }

    .chat-wrapper {
      .message-preview-position {
        position: absolute;
        right: 10px;
        bottom: calc(var(--control-height, 66px) + 20px);
        z-index: $z-index-message-preview;

        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }
    }
  }
}

:deep(.popper) {
  font-size: 0.8rem;
}
.them-wrapper {
  width: 32px;
  height: 32px;
  :deep(svg) {
    width: 24px;
    height: 24px;
  }
}

.lesson-button {
  margin-left: 9px;
}

.leave-button {
  color: $danger !important;
}
</style>
