<script setup lang="ts">
import { AVATAR_DEFAULT } from '@/data/room'
import { onImageError } from '@/helpers/favicon'
import { computed, PropType } from 'vue'
type DotColorType = 'green' | 'red' | 'yellow'
const props = defineProps({
  avatar: {
    type: String,
    default: AVATAR_DEFAULT,
  },
  size: {
    type: Number,
    default: 48,
  },
  hasDot: {
    type: Boolean,
    default: false,
  },
  dotColor: {
    type: String as PropType<DotColorType>,
    default: 'green',
  },
})

const width = computed(() => `${props.size}px`)
const height = computed(() => `${props.size}px`)
</script>

<template>
  <div class="avatar-wrapper" :class="[hasDot && 'has-dot']">
    <img :src="avatar || ''" alt="" crossorigin="anonymous" @error.once="onImageError" />
  </div>
</template>

<style lang="scss" scoped>
.avatar-wrapper {
  position: relative;
  width: v-bind(width);
  height: v-bind(height);
  display: flex;
  align-items: center;
  justify-content: center;

  // &:hover {
  //   border: 3px solid rgba(#ffffff, 0.1);
  // }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
  &.has-dot {
    &::after {
      content: '';
      position: absolute;
      bottom: 1px;
      right: 1px;
      height: 15px;
      width: 15px;
      border-radius: 50%;
      background: #26a06d;
      border: 2px solid white;
    }
  }
}
</style>
