<script setup lang="ts">
import { ref, computed, PropType } from 'vue'
import ReportProblemForm from '@/components/room/ReportProblemForm.vue'
import useSocketEvent from '@/composables/room/useSocketEvent'
import { ROOM_SPACE_URL } from '@/composables/api'
import useSpaceApi from '@/composables/api/useSpaceApi'
import useAuth from '@/composables/api/useAuth'
import { Engine } from '@/types/enums/room.enum'
import { useI18n } from 'vue-i18n'
import { EngineChangeSocketEmit } from '@/types/interfaces/room.interface'
import { engineAfter } from '@/composables/room/useRoomState'
import useGChatLog from '@/composables/api/useGChatLog'

interface ChangeVideoEnginePopupEmits {
  (e: 'update:modelValue', value: boolean): void
}

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  moveTo: {
    type: String as PropType<'main' | 'secondary' | 'whereby'>,
    default: 'secondary',
  },
})
const { t } = useI18n()
const emit = defineEmits<ChangeVideoEnginePopupEmits>()
const { getAccessInfo } = useAuth()
const { toggleEngine, switchToWhereBy } = useSpaceApi()
const { socket } = useSocketEvent()
const { sendSOSForChangeRoom } = useGChatLog()
const other = ref('')
const checkedIssues = ref<string[]>([])
const moveToTheRoom = computed(() =>
  props.moveTo === 'whereby'
    ? t('move-whereby-room', 'en')
    : props.moveTo === 'main'
    ? t('move-main-room', 'en')
    : t('move-secondary-room', 'en'),
)

const contentText = computed(() =>
  props.moveTo === 'whereby'
    ? t('room-changed.session-end-change-room.whereby-room')
    : props.moveTo === 'main'
    ? t('room-changed.session-end-change-room.main-room')
    : t('room-changed.session-end-change-room.second-room'),
)

const reportProblemPayload = computed(() => [
  ...checkedIssues.value,
  ...(other.value.trim() !== '' ? [`[99] ${other.value.trim()}`] : []),
])
const disabled = computed(() => (reportProblemPayload.value.length === 0 ? true : false))

const changeEngineToWhereby = async () => {
  try {
    const { status } = await switchToWhereBy(reportProblemPayload.value)
    if (status === 200) {
      engineAfter.value = Engine.WHEREBY
      socket.emit('engineChangedToWhereby')
      window.location.reload()
    }
  } catch (err) {
    console.log(err)
    alert('Cannot change to space whereby room')
  }
}
const changeEngineTwilioOrTokbox = async () => {
  try {
    const { queryToken } = getAccessInfo()
    await sendSOSForChangeRoom(reportProblemPayload.value.join())
    const { status, data } = await toggleEngine(reportProblemPayload.value)
    if (status === 200) {
      const isSpaceTiger = [Engine.TWILIO, Engine.ZOOM].includes(data.currentEngine)
      const dataEmit: EngineChangeSocketEmit = {
        currentEngine: data.currentEngine,
        version: isSpaceTiger ? 'space_tiger' : 'space5',
      }
      engineAfter.value = data.currentEngine
      socket.emit('engineChanged', dataEmit)
      if (engineAfter.value === Engine.TOKBOX) {
        window.location.href = `${ROOM_SPACE_URL}/join?token=${queryToken}&tiger`
      } else {
        window.location.reload()
      }
    }
  } catch (err) {
    console.log(err)
    alert('Cannot toggle engine')
  }
}
const submit = async () => {
  emit('update:modelValue', false)
  if (props.moveTo === 'whereby') {
    await changeEngineToWhereby()
  } else {
    await changeEngineTwilioOrTokbox()
  }
  checkedIssues.value = []
}
const close = () => {
  emit('update:modelValue', false)
  checkedIssues.value = []
}
</script>

<template>
  <Popup :title="moveToTheRoom" :open="modelValue" width="500" @close="close">
    <div class="text-base">
      <p>
        {{ contentText }}
      </p>
      <p class="mt-3">
        {{ t('room-changed.change-video-engine-content') }}
      </p>
      <ReportProblemForm v-model="checkedIssues" v-model:other="other"></ReportProblemForm>
    </div>
    <template #footer>
      <div class="footer-setting">
        <button :disabled="disabled" type="button" class="btn btn-primary" @click="submit">
          Submit
        </button>

        <button type="button" class="btn btn-tertiary" @click="close">Cancel</button>
      </div>
    </template>
  </Popup>
</template>

<style lang="scss" scoped>
.text-base {
  color: #4a4a4a;
}
.btn {
  font-size: 1.1rem;
}
</style>
