<script setup lang="ts">
import { computed, ref } from 'vue'
import { ROOM_SPACE_URL } from '@/composables/api'
import useAuth from '@/composables/api/useAuth'
import { useI18n } from 'vue-i18n'
import { EngineChangeSocketEmit } from '@/types/interfaces/room.interface'
import { Engine } from '@/types/enums/room.enum'
import useSocketEvent from '@/composables/room/useSocketEvent'

const props = defineProps({
  isMainEngine: {
    type: Boolean,
    default: false,
  },
})

const { t } = useI18n()
const { getAccessInfo } = useAuth()
const { socket } = useSocketEvent()
const isOpenedPopup = ref(false)
const engineChanged = ref()
const link = ref('')
const moveToContent = computed(() =>
  engineChanged.value === 'whereby'
    ? t('room-changed.content.whereby-room')
    : props.isMainEngine
    ? t('room-changed.content.second-room')
    : t('room-changed.content.main-room'),
)

const joinText = computed(() =>
  engineChanged.value === 'whereby'
    ? t('room-changed.join.whereby-room')
    : props.isMainEngine
    ? t('room-changed.join.second-room')
    : t('room-changed.join.main-room'),
)
const join = () => {
  isOpenedPopup.value = false
  window.location.href = link.value
  socket?.disconnect()
}

socket.on('engineChanged', (data: EngineChangeSocketEmit) => {
  const { queryToken } = getAccessInfo()
  const { currentEngine, version } = data
  isOpenedPopup.value = true
  if (currentEngine === Engine.TOKBOX || version === 'space5') {
    link.value = `${ROOM_SPACE_URL}/join?token=${queryToken}&tiger`
  } else {
    link.value = window.location.href
  }
})

socket.on('engineChangedToWhereby', () => {
  isOpenedPopup.value = true
  engineChanged.value = 'whereby'
  link.value = window.location.href
})
</script>

<template>
  <Popup
    title="Room changed"
    :open="isOpenedPopup"
    :can-close="false"
    @close="isOpenedPopup = false"
  >
    <div class="text-base text-content">
      <span>{{ moveToContent }}</span>
    </div>
    <template #footer>
      <button type="button" class="btn btn-primary text-base w-full" @click="join">
        {{ joinText }}
      </button>
    </template>
  </Popup>
</template>

<style lang="scss" scoped>
.text-content {
  color: #4a4a4a;
}
</style>
