import useSoundNotify from '@/composables/useSoundNotify'
import { ALLOW_TIME_AUTO } from '@/data/room'
import { Engine } from '@/types/enums/room.enum'
import { IPreviewMessage, ITab } from '@/types/interfaces/common.interface'
import moment from 'moment-timezone'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { useRoomStore } from './room.store'
import { isCoachAttend } from '@/composables/room/useSocketEvent'

const documentTab: ITab = { label: 'Document', value: 'document' }
const screenShareTab: ITab = { label: 'Screenshare', value: 'screenShare' }
const whiteboardTab: ITab = { label: 'Whiteboard', value: 'whiteboard' }
const engderTab: ITab = { label: 'Game', value: 'game' }

export const useRoomLayoutStore = defineStore('roomLayoutStore', () => {
  const participantSharedScreen = ref<string>('')
  const originalMaterialEnable = ref<boolean>(false)
  const documentEnable = ref<boolean>(false)
  const screenShareEnable = ref<boolean>(false)
  const whiteboardEnable = ref<boolean>(false)
  const helpEnable = ref<boolean>(false)
  const chatEnable = ref<boolean>(false)
  const stickerEnable = ref<boolean>(false)
  const gameEnable = ref<boolean>(false)
  const chatNotifyCount = ref<number>(0)
  const currentTab = ref<string>('')
  const newMessage = ref<IPreviewMessage | undefined>(undefined)
  const isOpenChangeVideoEngine = ref<boolean>(false)
  const changeToEngine = ref<Engine>()
  const soundNotify = useSoundNotify()
  const showStudentMessageCancelClass = ref<boolean>()
  const participantRequestCancelName = ref<string>('')
  const isOpenWelcomePopup = ref<boolean>(false)

  const roomStore = useRoomStore()

  const isFocus = computed(
    () =>
      documentEnable.value || screenShareEnable.value || gameEnable.value || whiteboardEnable.value,
  )

  const showComponentNightTime = computed(
    () =>
      !roomStore.isCoachRole &&
      roomStore.isNightTime &&
      !!parseInt(roomStore.room.externalRoomId || '') &&
      roomStore.room.metaData?.country === 'th',
  )

  const showFeedbackHelp = computed(() => showComponentNightTime.value && isCoachAttend.value)

  /**
   * Methods
   */

  const mainTabs = computed<ITab[]>(() => {
    const tabs: ITab[] = []
    if (documentEnable.value) tabs.push(documentTab)
    if (screenShareEnable.value) tabs.push(screenShareTab)
    if (whiteboardEnable.value) tabs.push(whiteboardTab)
    if (gameEnable.value) tabs.push(engderTab)
    return tabs
  })

  /**
   *
   * Methods
   */

  const changeCurrentTab = (tabValue?: string) => {
    currentTab.value = tabValue || ''
    if (!currentTab.value) {
      currentTab.value = mainTabs.value.length ? mainTabs.value[0].value : ''
    }
  }
  const updateUnreadMessages = (count: number) => {
    chatNotifyCount.value = count
  }

  const updatePreviewMessages = (messages?: IPreviewMessage) => {
    soundNotify.newMessage()
    newMessage.value = messages
  }
  return {
    // state
    showStudentMessageCancelClass,
    participantRequestCancelName,
    participantSharedScreen,
    originalMaterialEnable,
    documentEnable,
    screenShareEnable,
    whiteboardEnable,
    helpEnable,
    chatEnable,
    stickerEnable,
    gameEnable,
    newMessage,
    chatNotifyCount,
    currentTab,
    isOpenChangeVideoEngine,
    isOpenWelcomePopup,
    changeToEngine,

    // computed
    isFocus,
    mainTabs,
    showFeedbackHelp,
    showComponentNightTime,

    // methods
    updateUnreadMessages,
    updatePreviewMessages,
    changeCurrentTab,
  }
})
