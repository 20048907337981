<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue'
import PreviewTwilio from '@/components/checkup/PreviewTwilio.vue'
import PreviewVideo from '@/components/checkup/PreviewVideo.vue'
import PreviewZoom from '@/components/checkup/PreviewZoom.vue'
import useTestPage from '@/composables/useTestPage'
import { Engine } from '@/types/enums/room.enum'
import { useDeviceStore } from '@/stores'
import { setAudioInput, setAudioOutput, setVideoInput } from '@/services/device-state'

defineProps({
  canJoinRoom: {
    type: Boolean,
    default: false,
  },
})
const emit = defineEmits(['join-room'])

const isOpenPopup = ref(false)
// Store
const device = useDeviceStore()

// Hook
const { initLoading, engine, localParticipantInfo, alreadyParticipants, roomTitle, onInit } =
  useTestPage()

const isWherebyPreview = computed(() => engine.value === Engine.WHEREBY)

const openAlertDevice = () => {
  isOpenPopup.value = true
}

const changeCamera = (deviceId: string) => {
  device.maskSelectedVideo = deviceId
  setVideoInput(deviceId)
}
const changeMicrophone = (deviceId: string) => {
  device.maskSelectedAudio = deviceId
  setAudioInput(deviceId)
}
const changeSpeaker = (deviceId: string) => {
  device.maskSelectedSpeaker = deviceId
  setAudioOutput(deviceId)
}

watch(
  () => [device.permissionError, device.someDeviceIsGone],
  () => {
    if (device.permissionError || device.someDeviceIsGone) {
      openAlertDevice()
    }
  },
  { deep: true },
)

onMounted(async () => {
  await onInit()
  device.setEngine(engine.value)
  if (!localParticipantInfo?.value?.isInvisible && !isWherebyPreview.value) {
    device.initialize()
    if (engine.value === Engine.TWILIO) {
      device.clearLocalTracks()
    }
  }
})

onBeforeUnmount(() => device.unsubscribeDeviceChange())
</script>

<template>
  <DefaultLayout have-navbar theme="light" can-change-language>
    <LoadingProcess
      :loading="!localParticipantInfo?.isInvisible && !device.isReady && !isWherebyPreview"
      :message="`${$t('waiting-for-permission')}`"
      class="loading-ready"
    />
    <div
      v-if="initLoading && localParticipantInfo"
      class="test-device-page"
      :class="!localParticipantInfo.isInvisible && !isWherebyPreview ? '' : 'observer'"
    >
      <div
        class="left"
        :class="!localParticipantInfo.isInvisible && !isWherebyPreview ? '' : 'observer__padding'"
      >
        <div class="left-wrapper w-full">
          <h1
            class="text-base lg:text-2xl xl:text-3xl font-bold color-orange"
            :class="!localParticipantInfo.isInvisible && !isWherebyPreview ? '' : 'text-center'"
          >
            {{ roomTitle }}
          </h1>
          <template v-if="!localParticipantInfo.isInvisible && device.isReady && !isWherebyPreview">
            <PreviewTwilio
              v-if="engine === Engine.TWILIO"
              :can-set-background="true"
              :is-auto-state="true"
            />
            <PreviewZoom
              v-else-if="engine === Engine.ZOOM"
              :is-auto-state="true"
              :can-set-background="false"
              :mirror-canvas="true"
            />
            <PreviewVideo v-else />
          </template>
        </div>
      </div>
      <div class="right">
        <div class="already-in-room">
          <AvatarStack
            :avatars="alreadyParticipants"
            :limit="10"
            has-dot
            show-avatar-list
            class="flex flex-col items-center"
          >
            <template #avatar-list="{ avatarListLabel, nMoreAvatar }">
              <div v-if="alreadyParticipants.length">
                <span class="mr-1">{{ avatarListLabel }}</span>
                <span v-if="nMoreAvatar"
                  >{{ $t('more-avatar.and') }} {{ nMoreAvatar }} {{ $t('more-avatar.more') }}</span
                >
                <span class="ml-1 text-green-500">{{
                  alreadyParticipants.length > 1 ? $t('are-in-room') : $t('is-in-room')
                }}</span>
              </div>
              <div v-else>{{ $t('nobody-in-room') }}</div>
            </template>
          </AvatarStack>
        </div>
        <div
          v-show="device.isReady"
          class="w-full"
          :class="!localParticipantInfo.isInvisible && !isWherebyPreview ? '' : 'hidden'"
        >
          <SelectOption
            v-if="device.videoInputDevices.length"
            v-model="device.selectedVideo"
            :label="$t('camera')"
            :options="device.videoInputDevices"
            class="w-full"
            @update:model-value="changeCamera"
          />
          <template v-else>
            <p class="text-sm font-medium mt-3 text-black">{{ $t('camera') }}</p>
            <p class="text-base mb-3">No Camera Detected</p>
          </template>
          <SelectOption
            v-if="device.audioInputDevices.length"
            v-model="device.selectedAudio"
            :label="$t('microphone')"
            :options="device.audioInputDevices"
            class="w-full"
            @update:model-value="changeMicrophone"
          />
          <template v-else>
            <p class="text-sm font-medium mt-3 text-black">{{ $t('microphone') }}</p>
            <p class="text-base mb-3">No Microphone Detected</p>
          </template>
          <SelectOption
            v-if="device.canSetSpeaker && device.audioOutputDevices.length"
            v-model="device.selectedSpeaker"
            :label="$t('speaker')"
            :options="device.audioOutputDevices"
            class="w-full"
            @update:model-value="changeSpeaker"
          />
          <template v-else-if="device.canSetSpeaker && !device.audioOutputDevices.length">
            <p class="text-sm font-medium mt-3 text-black">{{ $t('speaker') }}</p>
            <p class="text-base">No Audio Output Detected</p>
          </template>
          <template v-else>
            <p class="text-sm font-medium mt-3 text-black">{{ $t('speaker') }}</p>
            <p class="text-base">System Default Audio Output</p>
          </template>
        </div>

        <div
          v-if="localParticipantInfo.isInvisible"
          class="flex my-2 p-6 h-fit bg-white rounded-lg border border-gray-200 shadow-md text-sm"
        >
          <SvgIcon class="icon-container mr-2" icon="info" :inherit-fill="true" height="16" />
          {{ $t('observer') }}
        </div>

        <div class="grid w-full">
          <button
            class="join-btn mt-6 py-2 px-4 h-12"
            type="button"
            :class="{
              'join-btn--warning':
                !localParticipantInfo.isInvisible &&
                (!device.videoInputDevices.length || !device.audioInputDevices.length),
            }"
            :disabled="!canJoinRoom"
            @click="emit('join-room')"
          >
            <Loading type="dot" class="mr-2" :is-loading="!canJoinRoom" />
            <span>{{ $t('join-room') }}</span>
          </button>
          <RouterLink
            :to="engine === Engine.TWILIO ? '/video-diagnostic' : '/zoom-diagnostic'"
            class="mt-6 m-auto link-test-device"
          >
            {{ $t('test-room') }}
          </RouterLink>
        </div>
      </div>
    </div>
  </DefaultLayout>
</template>
<style lang="scss" scoped>
.test-device-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  width: 100%;
  height: 100%;
  color: #4a4a4a;
  .color-orange {
    color: #f67519;
  }
  .left,
  .right {
    //
    padding: 1rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .right {
    .already-in-room {
      margin-top: 2rem;
    }
    .link-test-device {
      font-size: 0.9rem;
      color: #f67519;
      text-decoration: underline;
    }
  }

  @include media-breakpoint-up-custom(768px) {
    flex-direction: row;
    justify-content: center;
    padding: 60px 32px;
    min-height: 80vh;
    .left,
    .right {
      padding: 2rem;
      width: 50%;
      height: 100%;
    }
    .right {
      // width: 40%;
      min-width: 320px;
      max-width: 500px;
    }
    // .left {
    //   width: 60%;
    // }
  }
}

.join-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #ffffff;
  background: #f67519;
  box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.14);
  border-radius: 6px;
  &--warning {
    background: $warning;
  }
  &:disabled,
  &.disabled {
    background: $gs-4;
  }
}

.skip-button {
  color: #f67519;
  background-color: transparent;
  &:hover {
    background-color: #f675192a;
    transition: 0.4s;
  }
}

.observer {
  flex-direction: column !important;
  &__padding {
    padding-bottom: 0 !important;
  }
}

::v-deep(.select-option > label) {
  color: #000000;
}
.loading-ready {
  position: absolute !important;
  z-index: 1;
}
</style>
