import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Whereby from '/src/pages/Whereby.vue'
import Room from '/src/pages/Room.vue'
import Error from '/src/pages/Error.vue'
import JoinFail from '/src/pages/JoinFail.vue'
// import TestDevice from '/src/pages/TestDevice.vue'
import End from '/src/pages/End.vue'
import DocPage from '/src/pages/DocPage.vue'
import VideoDiagnostic from '/src/pages/VideoDiagnostic.vue'
import ZoomDiagnostic from '/src/pages/ZoomDiagnostic.vue'
import { roomGuard } from './router-guard'
const routes: RouteRecordRaw[] = [
  {
    path: '/',
    redirect: (to) => {
      // the function receives the target route as the argument
      // we return a redirect path/location here.
      return { name: 'room', query: to.query }
    },
  },
  {
    path: '/whereby',
    name: 'whereby',
    component: Whereby,
    beforeEnter: roomGuard,
  },
  {
    path: '/room',
    name: 'room',
    component: Room,
    beforeEnter: roomGuard,
  },
  {
    path: '/join-fail',
    name: 'join-fail',
    component: JoinFail,
  },
  {
    path: '/error',
    name: 'error',
    component: Error,
  },
  {
    path: '/end',
    name: 'end',
    component: End,
  },
  {
    path: '/doc/:id',
    name: 'document',
    component: DocPage,
  },
  {
    path: '/video-diagnostic',
    name: 'video-diagnostic',
    component: VideoDiagnostic,
  },
  {
    path: '/zoom-diagnostic',
    name: 'zoom-diagnostic',
    component: ZoomDiagnostic,
  },
]
const router = createRouter({
  history: createWebHistory(),
  routes,
})
export default router
