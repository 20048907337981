import { notify } from 'notiwind'

type ToastType = 'success' | 'fail' | 'warning' | 'info' | 'primary'
interface ToastOption {
  title?: string
  text: string
  component?: string
  icon?: string // name icon
  src?: string // for img
  onerror?(d: Event): void // for img
}
interface NotifyOption extends ToastOption {
  type: ToastType
}
const toast = ({ type, title, text, component, icon, src }: NotifyOption) => {
  notify(
    {
      group: 'general',
      type,
      title: title || type,
      text,
      data: {
        component: component || 'svg-icon',
        props: {
          src: src || '',
          icon: icon || type,
          inheritFill: true,
          crossorigin: component === 'img' ? 'anonymous' : undefined,
          height: '26',
        },
      },
    },
    4000,
  )
}
export default function useNotify() {
  return {
    success: (option: ToastOption) => {
      toast({ ...option, type: 'success' })
    },
    fail: (option: ToastOption) => {
      toast({ ...option, type: 'fail' })
    },
    info: (option: ToastOption) => {
      toast({ ...option, type: 'info' })
    },
    warning: (option: ToastOption) => {
      toast({ ...option, type: 'warning' })
    },
    primary: (option: ToastOption) => {
      toast({ ...option, type: 'primary' })
    },
  }
}
