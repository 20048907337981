<script setup lang="ts">
import { onMounted, ref } from 'vue'

import { logoPathLight } from '@/data/room'
import { PropType } from 'vue'
import useTheme from '@/composables/useTheme'
const props = defineProps({
  haveNavbar: {
    type: Boolean,
    default: false,
  },
  canChangeLanguage: {
    type: Boolean,
    default: false,
  },
  theme: {
    type: String as PropType<'dark' | 'light'>,
    default: 'light',
  },
  haveEngine: {
    type: Boolean,
    default: false,
  },
  isMainEngine: {
    type: Boolean,
    default: false,
  },
})
const currentRoomTooltip = ref(false)
const { changeTheme } = useTheme()
onMounted(() => {
  changeTheme(props.theme)
})
</script>

<template>
  <div class="public-layout" :class="[haveNavbar && '--with-navbar']">
    <div v-if="haveNavbar" class="nav text-center">
      <div class="left">
        <div class="logo">
          <img
            class="_prevent-text-select"
            :src="logoPathLight"
            height="54"
            crossorigin="anonymous"
          />
        </div>
        <div v-if="canChangeLanguage">
          <LanguageDropdown />
        </div>
        <Popper
          v-if="haveEngine"
          class="tooltip"
          placement="right"
          :show="currentRoomTooltip"
          :hover="true"
          :arrow="true"
          :content="
            isMainEngine
              ? $t('current-room-tooltip.main-room')
              : $t('current-room-tooltip.secondary-room')
          "
          @mouseover="currentRoomTooltip = true"
          @mouseleave="currentRoomTooltip = false"
        >
          <div class="_pointer-on-hover _prevent-text-select mr-3">
            <SvgIcon
              v-show="!isMainEngine"
              class="_pointer-on-hover"
              icon="siren"
              :inherit-fill="true"
              height="21"
            ></SvgIcon>
            <SvgIcon
              v-show="isMainEngine"
              class="_pointer-on-hover"
              icon="home"
              :inherit-fill="true"
              height="21"
            ></SvgIcon>
          </div>
        </Popper>
      </div>
    </div>
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.tooltip {
  font-size: 1rem;
}
.public-layout {
  position: relative;

  max-width: 100%;
  min-height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  background: $body-background;
  &.--with-navbar {
    padding-top: 60px;
  }
  > .page-wrapper {
    // width: 100%;
    // height: 100%;
  }
}

.nav {
  position: fixed;
  top: 0;
  height: 60px;
  width: 100%;
  padding: 1rem;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
  z-index: 2;
  display: flex;
  align-content: center;
  .left {
    display: flex;
    align-items: center;
    margin-right: 15px;

    > *:not(:last-child) {
      margin-right: 10px;
    }
    img {
      height: 32px;
    }
  }

  @include media-breakpoint-up-custom(768px) {
    padding: 1rem 3rem;
  }
}
</style>
