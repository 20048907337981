<script setup lang="ts">
import Joining from '@/components/room/Joining.vue'
import DeviceAlertPopup from '@/components/checkup/DeviceAlertPopup.vue'
import { onMounted, ref, onBeforeUnmount, watch } from 'vue'
import useAuth from '@/composables/api/useAuth'
import AuthState from '@/services/auth-state'
import { forceDisconnect } from '@/composables/room/useRoomLayout'
import { useDeviceStore, useRoomStore } from '@/stores'
import { useRouter } from 'vue-router'
import { Engine } from '@/types/enums/room.enum'
import useSocketEvent from '@/composables/room/useSocketEvent'

const isLoading = ref(false)
const isOpenPopup = ref(false)

const router = useRouter()
const { getAccessInfo } = useAuth()
const device = useDeviceStore()
const roomStore = useRoomStore()
const { socket } = useSocketEvent()

const reloadPage = () => {
  router.go(0)
}

const openAlertDevice = () => {
  isOpenPopup.value = true
}

watch(
  () => [device.permissionError, device.someDeviceIsGone],
  () => {
    if (device.permissionError || device.someDeviceIsGone) {
      openAlertDevice()
    }
  },
  { deep: true },
)

onBeforeUnmount(() => {
  if (!roomStore.localParticipantInfo?.isInvisible) {
    device.unsubscribeDeviceChange()
  }
})

onMounted(async () => {
  const { roomId, participantId } = getAccessInfo()
  isLoading.value = true
  if (!socket.connected) {
    socket.connect()
  }

  window.addEventListener('beforeunload', () => {
    if (!forceDisconnect.value) {
      AuthState.removeCountRoomTab()
    }
  })
  /**
   * Detect browser close for ios
   */
  window.addEventListener('pagehide', () => {
    if (!forceDisconnect.value) {
      AuthState.removeCountRoomTab()
    }
  })
  if (roomId && participantId) {
    await roomStore.connect(true)
  }
  AuthState.addCountRoomTab()
  socket.emit('updateParticipantForTimer', {
    userAgent: navigator.userAgent,
    participantId: roomStore.localParticipantInfo?.email,
    invisible: roomStore.localParticipantInfo?.isInvisible,
    isCoachRole: roomStore.localParticipantInfo?.isCoachRole,
  })
  socket.emit('updateTestDeviceState', {
    roomId,
    email: roomStore.localParticipantInfo?.email,
    invisible: roomStore.localParticipantInfo?.isInvisible,
    isInRoom: true,
  })
  isLoading.value = false
})
</script>

<template>
  <Joining :is-joining="isLoading" />
  <DeviceAlertPopup
    v-model="isOpenPopup"
    :content="device.permissionError?.message || 'Some device changed please republish again'"
    :is-republish="device.someDeviceIsGone"
    @republish="reloadPage"
  />
  <RoomLayout
    v-if="!isLoading && roomStore.room.id && roomStore.localParticipantInfo"
    :room-info="roomStore.room"
    :local-participant="roomStore.localParticipantInfo"
    :participants-info="roomStore.participants"
    :n-compartments="0"
    :is-focused="false"
    :is-loading="false"
    :is-coach-role="!!roomStore.isCoachRole"
    :is-student-role="!!roomStore.isStudentRole"
    :is-whereby="true"
    :engine="Engine.WHEREBY"
    :is-start-share-screen-with-video-element="false"
  >
    <template #default>
      <iframe
        v-if="roomStore.token"
        class="whereby-iframe"
        credentialless
        :src="`${roomStore.token}?embed&background=off&screenshare=${
          roomStore.localParticipantInfo.capabilities?.publishableTracks.screenShare ? 'on' : 'off'
        }&chat=off&displayName=${
          roomStore.localParticipantInfo.name
        }&leaveButton=off&iframeSource=globish-room`"
        allow="camera; microphone; fullscreen; speaker; display-capture"
      ></iframe>
    </template>
  </RoomLayout>
</template>

<style lang="scss" scoped>
.leave-button {
  margin-left: 10px;
}
.whereby-iframe {
  width: 100%;
  height: 100%;
  border: none;
}
</style>
