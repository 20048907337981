<script setup lang="ts">
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { ChevronDownIcon } from '@heroicons/vue/24/solid'
import { HelpAction, SolveAction } from '../../types/enums/help.enum'
import { changeToEngine, isOpenChangeVideoEngine } from '@/composables/room/useRoomLayout'
import { Engine } from '../../types/enums/room.enum'
import { studentHelpList, coachHelpList } from '@/data/mock-help-menu'
import { PropType, computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoomLayoutStore } from '@/stores/room-layout.store'
import { useRoomStore } from '@/stores'

const props = defineProps({
  isCoachRole: {
    type: Boolean,
    default: false,
  },
  isStudentRole: {
    type: Boolean,
    default: false,
  },
  engineWhereby: {
    type: Boolean,
    default: false,
  },
  engineControl: {
    type: Boolean,
    default: false,
  },
  country: {
    type: String,
    default: 'th',
  },
  currentEngine: {
    type: String as PropType<Engine>,
    default: Engine.TWILIO,
  },
  isMainEngine: {
    type: Boolean,
    default: true,
  },
  helpEnable: {
    type: Boolean,
    default: false,
  },
  canMoveRoomByCoach: {
    type: Boolean,
    default: true,
  },
})
const emit = defineEmits(['help-solve-confirm', 'on-close'])

const roomStore = useRoomStore()
const roomLayoutStore = useRoomLayoutStore()
const reloadTooltip = ref<boolean>(false)
const moveRoomSecondaryTooltip = ref<boolean>(false)
const moveRoomMainTooltip = ref<boolean>(false)
const moveRoomWherebyTooltip = ref<boolean>(false)
const disableChangeRoomTooltip = ref<boolean>(false)
const { t, locale } = useI18n()

const studentHelpMenuList = computed(() => studentHelpList(props.isMainEngine))
const coachHelpMenuList = computed(() => coachHelpList(props.isMainEngine))
const helpHeader = computed(() => {
  return {
    th: 'ท่านกำลังพบเจอปัญหาใด?',
    vn: 'Vấn đề bạn đang gặp phải là gì?',
    en: 'What is the problem you are encountering?',
  }
})
const localKey = computed(() => {
  switch (locale.value) {
    case 'th':
      return 'th'
    case 'vn':
      return 'vn'
    default:
      return 'en'
  }
})
const disableChangeRoom = computed(() => !props.canMoveRoomByCoach && props.isCoachRole)
const reload = () => {
  window.location.reload()
}
const openChangeVideoEngine = (engine?: Engine) => {
  isOpenChangeVideoEngine.value = true
  roomLayoutStore.isOpenChangeVideoEngine = true
  if (engine === Engine.WHEREBY) {
    changeToEngine.value = engine
    roomLayoutStore.changeToEngine = engine
  } else {
    roomLayoutStore.changeToEngine = changeToEngine.value =
      props.currentEngine === Engine.TWILIO ? Engine.TOKBOX : Engine.TWILIO
  }
}
const emitOpenPopup = (action: HelpAction, solved?: SolveAction) => {
  const helpRequest = {
    action: action,
    solvedBy: solved,
  }
  emit('help-solve-confirm', helpRequest)
}

const disclosureIndex = ref<number>()
const closeHelpMenu = () => {
  emit('on-close')
}
const toggleMenu = (index: number) => {
  if (disclosureIndex.value === index) {
    disclosureIndex.value = undefined
  } else {
    disclosureIndex.value = index
  }
}
</script>

<template>
  <div v-show="helpEnable" class="w-full h-full help-bg-body">
    <div class="tool-bar">
      <span>{{ $t('help') }}</span>
      <SvgIcon
        class="close-btn _pointer-on-hover"
        icon="close"
        height="25"
        :inherit-fill="true"
        @click="closeHelpMenu"
      ></SvgIcon>
    </div>

    <div class="menu-box flex flex-col w-full px-4 py-6 m-0 overflow-y-auto">
      <div class="mb-2">
        <!-- ปุ่ม reload มีทุกคน -->
        <Popper
          class="poper-tooltip"
          :hover="true"
          :arrow="true"
          content="Click here to refresh the room and join room again."
          @mouseover="reloadTooltip = true"
          @mouseleave="reloadTooltip = false"
        >
          <button class="menu-nav-list-item w-full" @click="reload">
            <SvgIcon
              class="_pointer-on-hover ml-1 mr-3 menu-icon"
              icon="reload"
              :inherit-fill="true"
              height="22"
            ></SvgIcon>
            <span class="text-base">{{ $t('reload-room') }}</span>
          </button>
        </Popper>

        <template v-if="engineControl || engineWhereby">
          <template v-if="engineControl">
            <Popper
              v-if="isMainEngine"
              class="poper-tooltip"
              :hover="true"
              :arrow="true"
              :content="
                disableChangeRoom
                  ? 'Must have student enter class before change room'
                  : 'Click here to move to the second room.'
              "
              @mouseover="moveRoomSecondaryTooltip = true"
              @mouseleave="moveRoomSecondaryTooltip = false"
            >
              <button
                class="menu-nav-list-item"
                :disabled="disableChangeRoom"
                @click="openChangeVideoEngine()"
              >
                <SvgIcon
                  class="_pointer-on-hover ml-1 mr-3 menu-icon"
                  icon="siren"
                  :inherit-fill="true"
                  height="19"
                ></SvgIcon>
                <span class="text-base">{{ $t('move-secondary-room') }}</span>
              </button>
            </Popper>

            <Popper
              v-else
              class="poper-tooltip"
              :hover="true"
              :arrow="true"
              :content="
                disableChangeRoom
                  ? 'Must have student enter class'
                  : 'Click here to move to the main room.'
              "
              @mouseover="moveRoomMainTooltip = true"
              @mouseleave="moveRoomMainTooltip = false"
            >
              <button
                class="menu-nav-list-item"
                :disabled="disableChangeRoom"
                @click="openChangeVideoEngine()"
              >
                <SvgIcon
                  class="_pointer-on-hover ml-1 mr-3 menu-icon"
                  icon="change-room"
                  :inherit-fill="true"
                  height="19"
                ></SvgIcon>
                <span class="text-base">{{ $t('move-main-room') }}</span>
              </button>
            </Popper>
          </template>
          <Popper
            v-if="engineWhereby"
            class="poper-tooltip"
            :hover="true"
            :arrow="true"
            :content="
              disableChangeRoom
                ? 'Must have student enter class before change room'
                : 'Click here to move the whereby room.'
            "
            @mouseover="moveRoomWherebyTooltip = true"
            @mouseleave="moveRoomWherebyTooltip = false"
          >
            <button
              class="menu-nav-list-item"
              :disabled="disableChangeRoom"
              @click="openChangeVideoEngine(Engine.WHEREBY)"
            >
              <SvgIcon
                class="_pointer-on-hover ml-1 mr-3 menu-icon"
                icon="siren"
                :inherit-fill="true"
                height="19"
              ></SvgIcon>
              <span class="text-base">{{ $t('move-whereby-room') }}</span>
            </button>
          </Popper>
        </template>
      </div>
      <template v-if="isStudentRole">
        <div class="header py-4 pl-2">
          {{ helpHeader[localKey] }}
        </div>
        <div class="flex flex-col w-full">
          <Disclosure v-for="(help, index) in studentHelpMenuList" :key="`help-collapse-${index}`">
            <DisclosureButton
              class="help-header w-full flex items-center text-left text-base"
              @click="toggleMenu(index)"
            >
              <span>{{ help.header[localKey] }} </span>
              <ChevronDownIcon
                class="ml-auto h-5 w-5"
                :class="disclosureIndex === index ? 'rotate-180 transform' : ''"
              />
            </DisclosureButton>
            <DisclosurePanel
              v-show="disclosureIndex === index"
              :static="true"
              class="text-base px-3"
            >
              <div class="flex flex-row">
                <div class="rectangle"></div>
                <p class="text-black text-sm dark:text-white py-4">
                  {{ help.content?.[localKey] }}
                </p>
              </div>

              <div class="flex flex-col gap-2 p-4">
                <div v-for="(choice, id) in help.choices" :key="`solved-btn-${id}`" class="w-full">
                  <button
                    type="button"
                    class="btn choice-btn w-full"
                    @click="emitOpenPopup(help.action, choice.solveBy)"
                  >
                    <SvgIcon
                      class="_pointer-on-hover ml-1 mr-3 choice-icon"
                      :icon="choice.icon"
                      :inherit-fill="true"
                      height="20"
                    ></SvgIcon>
                    <p>
                      {{ choice.label[localKey] }}
                    </p>
                  </button>
                  <p
                    v-if="
                      help.choices?.length &&
                      help.choices?.length > 1 &&
                      id < help.choices?.length - 1
                    "
                    class="text-center text-black dark:text-white mt-2 text-sm"
                  >
                    {{ $t('or') }}
                  </p>
                </div>
              </div>
            </DisclosurePanel>
          </Disclosure>
        </div>
      </template>

      <!-- Coach help -->
      <template v-if="isCoachRole">
        <div class="header py-4 pl-2">Are you experiencing any problems?</div>

        <div class="flex flex-col w-full">
          <Disclosure v-for="(help, index) in coachHelpMenuList" :key="`help-collapse-${index}`">
            <DisclosureButton
              class="help-header w-full flex items-center text-left"
              @click="toggleMenu(index)"
            >
              <span>{{ 'th' == locale ? help.header.th : help.header.en }}</span>
              <ChevronDownIcon
                class="ml-auto h-5 w-5"
                :class="disclosureIndex === index ? 'rotate-180 transform' : ''"
              />
            </DisclosureButton>
            <DisclosurePanel
              v-show="disclosureIndex === index"
              :static="true"
              class="text-base px-3"
            >
              <div class="flex flex-row">
                <div class="rectangle"></div>
                <p class="text-black text-sm dark:text-white py-4">
                  {{ locale === 'th' ? help.content?.th : help.content?.en }}
                </p>
              </div>

              <div class="flex flex-col gap-2 p-4">
                <div v-for="(choice, id) in help.choices" :key="`solved-btn-${id}`" class="w-full">
                  <Popper
                    v-if="
                      disableChangeRoom &&
                      roomStore.isNightTime &&
                      choice.solveBy === SolveAction.MOVE_ROOM
                    "
                    class="poper-tooltip"
                    placement="top"
                    :hover="true"
                    :arrow="true"
                    content="Must have student enter class before change room"
                    @mouseover="disableChangeRoomTooltip = true"
                    @mouseleave="disableChangeRoomTooltip = false"
                  >
                    <button
                      type="button"
                      class="btn choice-btn w-full"
                      disabled
                      @click="emitOpenPopup(help.action, choice.solveBy)"
                    >
                      <SvgIcon
                        class="_pointer-on-hover ml-1 mr-3 choice-icon"
                        :icon="choice.icon"
                        :inherit-fill="true"
                        height="20"
                      ></SvgIcon>
                      <p>
                        {{ locale === 'th' ? choice.label.th : choice.label.en }}
                      </p>
                    </button>
                  </Popper>
                  <button
                    v-else
                    type="button"
                    class="btn choice-btn w-full"
                    @click="emitOpenPopup(help.action, choice.solveBy)"
                  >
                    <SvgIcon
                      class="_pointer-on-hover ml-1 mr-3 choice-icon"
                      :icon="choice.icon"
                      :inherit-fill="true"
                      height="20"
                    ></SvgIcon>
                    <p>
                      {{ locale === 'th' ? choice.label.th : choice.label.en }}
                    </p>
                  </button>
                  <p
                    v-if="
                      help.choices?.length &&
                      help.choices?.length > 1 &&
                      id < help.choices?.length - 1
                    "
                    class="text-center text-black dark:text-white mt-2 text-sm"
                  >
                    {{ $t('or') }}
                  </p>
                </div>
              </div>
            </DisclosurePanel>
          </Disclosure>
        </div>
      </template>
      <div class="room-version">
        <small>About</small>
        <small>{{ roomStore.currentTag }}</small>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: var(--bg-chat-msg);
}
.poper-tooltip {
  border: 0 !important;
  width: 100%;
  font-size: 12px;
  margin: 0 !important;
}
.help-bg-body {
  --header-height: 67px;
  background: var(--bg-chat);
  border-radius: 6px 6px 0 0;
  overflow: hidden;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  color: var(--text-chat);
  @include media-breakpoint-up-custom(1200px) {
    border-radius: 6px;
  }
  .tool-bar {
    background-color: var(--primary-color);
    color: white;
    height: var(--header-height);
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    align-items: center;
    font-weight: 600;
    .close-btn {
      color: white;
    }
  }
  .menu-box {
    display: flex;
    flex-direction: column;
    height: calc(100% - var(--header-height));
  }
}

.menu-nav-list-item {
  color: #303030;
  border: 1px solid #4f4f4f;
  border-radius: 8px;
  margin-bottom: 10px;
  .menu-icon {
    border-radius: 99px;
    background-color: #2d9cdb;
    padding: 3px;
    color: white;
  }
  &:hover {
    &:not([disabled]) {
      background-color: rgba(189, 189, 189, 0.3);
    }
  }
  &:disabled {
    cursor: default;
    opacity: 0.5;
    .menu-icon {
      cursor: default;
    }
  }
}

.header {
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: #303030;
  margin-bottom: 0.75rem;
  border-bottom: 1.5px solid #303030;
}
.help-header {
  padding: 1rem 0.5rem;
  color: #000000;
  font-size: 0.875rem;
  &:not(:first-child) {
    border-top: 1px solid #4f4f4f;
  }
  &:hover,
  &:focus {
    background-color: rgba(189, 189, 189, 0.3);
  }
}
.rectangle {
  min-width: 9px;
  background-color: #c6c6c6;
  border-radius: 4px;
  margin-right: 10px;
  display: inline-block;
}
.choice-btn {
  display: flex;
  justify-content: center;
  align-items: center;

  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: black;
  border: 1px solid #4f4f4f;
  border-radius: 7px;
  .choice-icon {
    border-radius: 99px;
    background-color: #2d9cdb;
    padding: 3px;
    color: white;
  }
  &:hover,
  &:focus {
    &:not([disabled]) {
      background-color: rgba(189, 189, 189, 0.3);
    }
  }
  &:disabled {
    cursor: default;
    opacity: 0.5;
    .choice-icon {
      cursor: default;
    }
  }
}

.room-version {
  margin-top: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  color: #c6c6c6;
  font-size: 15px;
  margin-bottom: -8px;
}

hr {
  border: 1px #d5d5d5 solid;
}
.dark {
  .close-btn {
    color: #ffffff !important;
  }
  .menu-nav-list-item {
    color: $gs-6;
    border: 1px solid #4f4f4f;
    &:hover {
      &:not([disabled]) {
        background: #333333;
      }
    }
    &:disabled {
      cursor: default;
      opacity: 0.5;
      .menu-icon {
        cursor: default;
      }
    }
  }
  hr {
    border: 1px #cccccc solid;
  }
  .help-bg-body {
    background-color: #272727;
  }
  .header {
    color: $gs-6;
    border-bottom: 1.5px solid white;
  }
  .help-header {
    &:not(:first-child) {
      border-top: 1px solid #4f4f4f;
    }
    color: $gs-6;
    &:hover,
    &:focus {
      background-color: rgb(50, 50, 46, 0.3);
    }
  }
  .rectangle {
    background: #333333;
  }
  .choice-btn {
    color: $gs-6;
    border: 1px solid #4f4f4f;
    &:hover,
    &:focus {
      &:not([disabled]) {
        background-color: #4f4f4f;
      }
    }
    &:disabled {
      cursor: default;
      opacity: 0.5;
      .menu-icon {
        cursor: default;
      }
    }
  }

  .room-version {
    color: #9f9f9f;
  }
}
</style>
